import Cta from '../components/Cta';
import HomeFeatures from '../components/HomeFeatures';
import HomeFeaturesTwo from '../components/HomeFeaturesTwo';
import HomeHeroFour from '../components/HomeHeroFour';
import MembershipBenefits from '../components/MembershipBenefits';
import PricingSection from '../components/PricingSection';
import { SEO } from '../components/seo';

export default function Home() {
  return (
    <article>
      <SEO translationKey='seo.home' />

      <HomeHeroFour />
      <HomeFeatures />
      <HomeFeaturesTwo />
      <PricingSection />
      <MembershipBenefits />
      <Cta />
    </article>
  );
}
