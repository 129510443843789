import { Button } from '@/components/ui/button';
import Link from 'next/link';
import { useTranslation } from '../hooks/useTranslation';

export default function Cta() {
  const { t } = useTranslation();

  return (
    <div className=''>
      <div className='mx-auto max-w-7xl px-6 pt-10 pb-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8'>
        <h2 className='text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50 sm:text-4xl'>
          {t('cta.title')}
          <br />
          <span className='text-green-600'>{t('cta.subtitle')}</span>
        </h2>

        <div className='mt-10 flex md:items-center gap-6 lg:mt-0 lg:flex-shrink-0 flex-col md:flex-row'>
          <Button asChild size='lg'>
            <Link href='/search'>{t('cta.searchByCard')}</Link>
          </Button>
          <Button asChild size='lg'>
            <Link href='/sets'>{t('cta.searchBySet')}</Link>
          </Button>
        </div>
      </div>
    </div>
  );
}
