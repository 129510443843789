import Image from 'next/image';
import { Badge } from './ui/badge';
import { useTranslation } from '../hooks/useTranslation';

export default function HomeFeatures() {
  const { t } = useTranslation();
  const features = t('home.features.section1.features');

  return (
    <div className='relative overflow-hidden py-24 sm:pb-32 sm:pt-48'>
      <div className='relative mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2'>
          <div className='lg:ml-auto lg:pt-4'>
            <div className='lg:max-w-lg'>
              <Badge>{t('home.features.section1.badge')}</Badge>
              <h2 className='mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-50'>{t('home.features.section1.title')}</h2>
              <p className='mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400'>{t('home.features.section1.subtitle')}</p>
              <dl className='mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none'>
                {features.map((feature) => (
                  <div key={feature.name} className='relative transition-all duration-200 hover:translate-x-1'>
                    <dt className='inline font-semibold text-gray-900 dark:text-gray-50'>
                      <div className='absolute left-0 top-1 size-6 flex items-center justify-center rounded-lg bg-primary/10'>
                        <div className='size-2 rounded-full bg-primary'></div>
                      </div>
                      {feature.name}
                    </dt>
                    <dd className='inline ml-2 text-gray-600 dark:text-gray-400'>{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          <div className='flex items-start justify-end lg:order-first'>
            <div className='relative'>
              <div className='absolute -inset-4'>
                <div className='mx-auto w-full h-full max-w-4xl bg-gradient-to-r from-primary/30 to-secondary/30 blur-3xl opacity-20'></div>
              </div>
              <Image
                src='/cart-3.png'
                alt={t('home.features.section1.images.cartScreenshot')}
                className='relative w-[48rem] max-w-none rounded-xl shadow-2xl ring-1 ring-gray-400/10 transition-all duration-300 hover:scale-[1.01] sm:w-[57rem]'
                width={2214 / 2}
                height={1560 / 2}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
